<template>
  <div id="app">
    <HelloWorld msg="Welcome to DataGen"/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.logo {
  height: 100px;
  margin-right: 20px;
}

.icon {
  width: 100px;
  height: 100px;
  color: #007bff; /* Use your preferred color */
}
</style>
