<template>
  <div class="hello">
    <div class="header">
      <DatabaseIcon class="centered-icon" />
      <div class="tool-description">
        <h1 class="main-title">Data Generator</h1>
        <p class="subtitle">Create custom datasets for data science, data engineering, and AI research</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/TeIPqeGMNrw?si=LLeerDqM8xUGUs8M"
          title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
    </div>

    <div class="content">
      <section class="card">
        <h2 class="section-title">Create New Dataset</h2>
        <div class="dataset-info">
          <input v-model="datasetTitle" type="text" placeholder="Dataset Title">
          <input v-model="datasetDescription" type="text" placeholder="Dataset Description">
        </div>

        <!-- New notice about string length limitation -->
        <div class="notice">
          <p><strong>Free Tier:</strong> String objects generated by the model are limited to 200 characters.</p>
        </div>


        <table>
          <thead>
            <tr class="table-header">
              <th v-for="header in tableHeaders"
                  :key="header.id">
                {{ header.label }}
                <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <v-icon
              v-bind="props"
              icon="mdi-information"
              color="info"
              size="small"
              class="info-icon ms-1"
            ></v-icon>
          </template>
          <div class="tooltip-content">
            <strong>{{ header.label }}</strong>
            <p>{{ header.description }}</p>
            <em v-if="header.example">Example: {{ header.example }}</em>
                  </div>
                </v-tooltip>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in tableData" :key="index" class="table-row">
              <td><input v-model="row.name" placeholder="Enter variable name" :disabled="row.isLocked"></td>
              <td>
                <select v-model="row.type" @change="resetMinMax(row)" class="styled-select" :disabled="row.isLocked">
                  <option value="ID">Identifier</option>
                  <option value="Boolean">Boolean</option>
                  <option value="Category">Category</option>
                  <option value="Decimal">Decimal</option>
                  <option value="String">String</option>
                  <option value="Integer">Integer</option>
                </select>
              </td>
              <td><input v-model="row.description" placeholder="Enter description" :disabled="row.isLocked"></td>
              <td>
                <input v-if="row.type !== 'Boolean' && row.type !== 'Category' && row.type !== 'ID'" :value="row.min" type="number"
                  :placeholder="getMinPlaceholder(row.type)" @input="updateMinValue(row, $event)" :disabled="row.isLocked">
              </td>
              <td>
                <input v-if="row.type !== 'Boolean' && row.type !== 'Category' && row.type !== 'ID'" :value="row.max" type="number"
                  :placeholder="getMaxPlaceholder(row.type)" @input="updateMaxValue(row, $event)" :disabled="row.isLocked">
              </td>
              <td>
                <textarea v-if="row.type === 'Category'" v-model="row.categories" placeholder="Enter categories" class="styled-textarea" :disabled="row.isLocked"></textarea>
              </td>
              <td class="action-buttons">
                <button v-if="!row.isLocked" class="btn save-btn" @click="lockRow(index)">
                    <v-icon>mdi-content-save</v-icon>
                </button>
                <button v-if="row.isLocked" class="btn edit-btn" @click="unlockRow(index)">
                     <v-icon>mdi-pencil</v-icon>
                </button>
                <button @click="deleteRow(index)" class="btn delete-btn">
                    <v-icon>mdi-delete</v-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <button @click="addRow" class="btn add-btn">Add Variable</button>

        <div class="generate-section">
          <input v-model="email" type="email" placeholder="Enter your email">
          <select v-model="recordCount">
            <option value="100">100 Records</option>
            <option value="500">500 Records</option>
            <option value="1000">1000 Records</option>
          </select>
          <button @click="confirmGenerateData" class="btn generate-btn">Generate Dataset</button>
        </div>
      </section>
      <!-- add section called Visualize your data. this should receive just .csv files and called a api request sending the file and getting a string text to show the user 
      <section class="card visualize-section">
  <h2 class="section-title">Visualize Your Data (In Testing)</h2>-->
  <!-- title and description input 
  <div class="upload-container">
    <input
      type="file"
      ref="fileInput"
      @change="handleFileUpload"
      accept=".csv"
      class="file-input"
      :disabled="isLoading"
    >
    <div class="upload-box" @click="triggerFileInput">
      <v-icon large>mdi-file-upload</v-icon>
      <p>Drop your CSV file here or click to browse</p>
    </div>
  </div>
-->
  <!-- Loading indicator 
  <div v-if="isLoading" class="loading">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
    <span>Analyzing data...</span>
  </div>-->

  <!-- Results display 
  <div class="scrollable-wrapper">
    <div v-if="visualizationResult" class="result-container">
      <h3>Streamlit Code</h3>
      <pre><code class="python" v-html="highlightedCode"></code></pre>
    </div>
  </div>
   <button class="btn copy-btn" @click="copyToClipboard" v-if="visualizationResult">{{ copySuccess ? 'Copied!' : 'Copy Code' }}</button>
      </section>  
-->
      <section class="card roadmap-section">
        <h2 class="section-title">Roadmap</h2>
        <ul class="roadmap-list">
          <li class="roadmap-card">
            <span class="symbol"><v-icon>mdi-check-circle</v-icon></span>
            <strong>Q4 2024 New Variables:</strong>  Use Unique ID and Category variables in your dataset.
          </li>
          <li class="roadmap-card">
            <span class="symbol"><v-icon>mdi-circle-outline</v-icon></span>
            <strong>Q4 2024 Json Output:</strong>  Generate Json output for your dataset.
          </li>
           <li class="roadmap-card">
            <span class="symbol"><v-icon>mdi-circle-outline</v-icon></span>
            <strong>Q4 2024 Generate LLMs Train Data:</strong> Generate conversational datasets for training LLMs.
          </li>
          <li class="roadmap-card">
            <span class="symbol"><v-icon>mdi-circle-outline</v-icon></span>
            <strong>Q1 2025 Free Dataset Search:</strong> Search for and download datasets previously created.
          </li>
          <li class="roadmap-card">
            <span class="symbol"><v-icon>mdi-circle-outline</v-icon></span>
            <strong>Q1 2025 Generate From Template:</strong> Generate datasets from templates giving a few examples.
          </li>
          <li class="roadmap-card">
            <span class="symbol"><v-icon>mdi-circle-outline</v-icon></span>
            <strong>Q1 2025 Welcome Users:</strong> Create an account and view the history of generated datasets.
          </li>
          <li class="roadmap-card">
            <span class="symbol"><v-icon>mdi-circle-outline</v-icon></span>
            <strong>Q1 2025 Premium Dataset Generation:</strong> Pay to generate larger datasets.
          </li>
          <li class="roadmap-card">
            <span class="symbol"><v-icon>mdi-circle-outline</v-icon></span>
            <strong>Q1 2025 API Integration:</strong> Integrate the DataGen API into your own apps and data pipelines.
          </li>
        </ul>
      </section>

      <section class="card request-functionality">
        <h2 class="section-title">Request a Functionality</h2>
        <p>If you have any suggestions or would like to request a new functionality, please fill out the form below:</p>
        <a href="https://forms.gle/wbQ3fsF66XpohS23A" class="btn request-link">Request a Functionality</a>
      </section>
    </div>
  </div>
</template>

<script>
import hljs from 'highlight.js/lib/core';
import python from 'highlight.js/lib/languages/python';
import 'highlight.js/styles/vs2015.css'; // Dark theme, you can choose another
import axios from 'axios';
import DatabaseIcon from './DatabaseIcon.vue'
hljs.registerLanguage('python', python);
export default {
  name: 'HelloWorld',
  components: {
    DatabaseIcon
  },
  props: {
    msg: String
  },
  computed: {
    highlightedCode() {
      // Format the code before highlighting
      const formattedCode = this.visualizationResult
        .split('\n')
        .map(line => line.trimStart()) // Remove leading spaces only
        .join('\n');
      
      return hljs.highlight(formattedCode, {
        language: 'python'
      }).value;
    }
  },
  data() {
    return {
      copySuccess: false,
      tableData: [{ name: '', type: 'String', description: '', min: null, max: null, categories: "", isLocked: false }],
      email: '',
      datasetTitle: '',
      datasetDescription: '',
      recordCount: '500',
      API_URL: process.env.API_URL,
      tableHeaders: [ 
        { id: 'name', label: 'Variable Name', description: 'Enter a unique name for your variable.' },
        { id: 'type', label: 'Data Type', description: 'Select the type of data for your variable.' },
        { id: 'description', label: 'Description', description: 'Enter a description for your variable. You can give some examples of what the data will look like.'},
        { id: 'min', label: 'Min Value', description: 'Enter the minimum value for your variable.' },
        { id: 'max', label: 'Max Value', description: 'Enter the maximum value for your variable.' },
        { id: 'categories', label: 'Categories', description: 'Enter categories for your variable. Separate each category with a dot. Use the format:\nname, when condition\nFor example:\nyouth, when age is less than 18.\nadult, when age is more than or equal to 18.' },
        { id: 'actions', label: 'Actions', description: 'Save, Edit, Delete your variable' }
      ],
      isLoading: false,
      visualizationResult: null,
    }
  },
  methods: {
    async copyToClipboard() {
      try {
        // Get the original text without HTML formatting
        await navigator.clipboard.writeText(this.visualizationResult);
        // Show success state
        this.copySuccess = true;
        // Reset button after 2 seconds
        setTimeout(() => {
          this.copySuccess = false;
        }, 2000);
      } catch (err) {
        console.error('Failed to copy:', err);
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    async handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      // Validate file type
      if (!file.type && !file.name.endsWith('.csv')) {
        alert('Please upload a CSV file');
        return;
      }

      try {
        this.isLoading = true;
        const formData = new FormData();
        if (!file) {
          alert('Please enter a file');
          return;
        }
        formData.append('file', file);

        const response = await axios.post(
          `${this.API_URL}/generate-visualization/`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );

        this.visualizationResult = response.data.visualization_code;
      } catch (error) {
        console.error('Error analyzing file:', error);
        alert('Error analyzing file. Please try again.');
      } finally {
        this.isLoading = false;
      }
    },
    addRow() {
      if (this.tableData.length >= 10) {
        console.log("row limit reached");
        alert("You have reached the maximum number of variables (10).");
        return; // Prevent adding more rows
      }

      this.tableData.push({ name: '', type: 'String', description: '', min: null, max: null, categories: "" });
    },
    lockRow(index) {
      console.log("locking row", index);
      console.log(this.tableData[index]);
      if (this.isRowValid(this.tableData[index], index)) {
        this.tableData[index].isLocked = true;
      }
    },

    unlockRow(index) {
      this.tableData[index].isLocked = false;
      this.tableData[index].categories = this.formatCategories(this.tableData[index].categories);
    },
    formatCategories(categories) {
      if (Array.isArray(categories)) {
        return categories.map(cat => `${cat.name}, ${cat.description}`).join('.\n');
      }
      return categories;
    },
    isRowValid(row, index) {

      if (row.type !== 'Category') {
        row.categories = [];
      }
      // Basic validation
      console.log("validating row", index);
      if (!row.name || !row.type || !row.description) {
        alert("Please enter a variable name, data type, and description");
        return false;
      }

      // Type-specific validation
      if (row.type === 'Category') {
        this.tableData[index].categories = this.validateAndFormatCategories(row.categories);
        if (this.tableData[index].categories === null) {
          alert("Please enter categories in the format:\nname, when condition\nFor example:\nyouth, when age is less than 18.\nadult, when age is more than or equal to 18");
          return false;
        }
      }

      if (row.type !== 'Boolean' && row.type !== 'ID' && row.type !== 'Category') {
        if (row.min !== null && row.max !== null && row.max >= row.min) {
          return true;
        }
        else {
          alert("Please enter a valid min and max value");
          return false;
        }
      }

      return true;
    },
    async generateData() {
      // check that all rows are saved and locked
      if (!this.tableData.every(row => row.isLocked)) {
        alert('Please save all rows before generating data');
        return;
      }

      if (!this.email || !this.datasetTitle) {
        alert('Please enter an email address and dataset title');
        return;
      }

      if (!this.validateAllRows()) {
        alert('Please correct the min and max values');
        return;
      }

      // Check if the email has any ongoing data generation
      const result = await this.checkEmailStatus(this.email)
      if (!result) {
        alert('You have reached the day free tier limit (2 datasets). Please upgrade to a paid tier to generate more data.');
        return;
      }

      try {
        console.log('Sending data to backend...', this.tableData);
        console.log('Email:', this.email);
        console.log('Title:', this.datasetTitle);
        console.log('Description:', this.datasetDescription);
        console.log('Record count:', this.recordCount);
        const response = await axios.post(`${this.API_URL}/generate-synthetic-data/`, {
          email: this.email,
          title: this.datasetTitle,
          description: this.datasetDescription,
          n_records: this.recordCount,
          variables: this.tableData
        });
        console.log('Response:', response.data);
        alert('Data generation started successfully! You will receive the data in your email as a CSV file once it\'s complete.');
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred while initiating data generation');
      }
    },
    resetMinMax(row) {
      row.min = null;
      row.max = null;
    },
    updateMinValue(row, event) {
      const value = event.target.value;
      row.min = value === '' ? null : Number(value);
    },
    updateMaxValue(row, event) {
      const value = event.target.value;
      row.max = value === '' ? null : Number(value);
    },
    validateAllRows() {
      return this.tableData.every(row => {
        if (row.type === 'Boolean' || row.type === 'Category' || row.type === 'ID') return true;
        return row.min !== null && row.max !== null && row.max >= row.min;
      });
    },
    getMinPlaceholder(type) {
      return type === 'String' ? 'Min words' : 'Min value';
    },
    getMaxPlaceholder(type) {
      return type === 'String' ? 'Max words' : 'Max value';
    },
    async checkEmailStatus(email) {
      try {
        // Replace with your actual API endpoint
        const response = await axios.get(`${this.API_URL}/datagen-generated/${email}`);
        return response.data;
      } catch (error) {
        console.error('Error checking email status:', error);
        return false;
      }
    },
    deleteRow(index) {
      if (this.tableData.length > 1) {
        this.tableData.splice(index, 1);
      } else {
        alert("You cannot delete the last row.");
      }
    },
    confirmGenerateData() {
      if (confirm('Double check your dataset variables and email.Are you sure you want to generate the dataset?')) {
        this.generateData();
      }
    },
    validateAndFormatCategories(categoriesText) {
    try {
      // Split by newlines or commas and clean up whitespace
      const categories = categoriesText.split(/[.\n]/)
        .map(cat => cat.trim())
        .filter(cat => cat.length > 0);

      // Validate each category has the correct format
      const validCategories = categories.every(cat => {
        const parts = cat.split(',').map(part => part.trim());
        return parts.length === 2 && parts[0].length > 0 && parts[1].length > 0;
      });

      if (!validCategories) {
          throw new Error('Invalid category format. Each category should be in the format: "category1, condition1. category2, condition2."');
      }

      // Convert to array of objects
      return categories.map(cat => {
        const [name, condition] = cat.split(',').map(part => part.trim());
        return {
          name: name,
          description: condition
        };
      });
    } catch (error) {
      return null;
    }
  },

  updateCategories(row, event) {
    const categoriesText = event.target.value;
    const formattedCategories = this.validateAndFormatCategories(categoriesText);
    
    if (formattedCategories === null) {
        alert('Please use the format:\nname, when condition\nFor example:\nyoung, when age is less than 18\nadult, when age is more than or equal to 18');
        return;
      }
    
      row.categories = formattedCategories;
    }
  },
  formatCategories(categories) {
    // If categories is an array of objects, format it into string
    if (Array.isArray(categories)) {
      return categories
        .map(cat => `${cat.name}, ${cat.description}`)
        .join('.\n');
    }
    // If it's already a string, return as is
    return categories;
  }
}
</script>

<style scoped>
:root {
  --primary-color: #4a90e2; /* This should match the blue in your database icon */
  --primary-hover: #3a7bc8; /* A slightly darker shade for hover effects */
  --background-color: #f8f9fa;
  --text-color: #333;
  --card-background: #ffffff;
  --button-text-color: #ffffff;
  --table-header-background: #e9f7fe;
  --table-header-text: #333;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
}

.hello {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  text-align: center;
  margin-bottom: 40px;
}

.main-title {
  font-size: 3rem;
  color: var(--primary-color);
  margin-bottom: 10px;
}

.subtitle {
  font-size: 1.2rem;
  color: var(--text-color);
}

.card {
  background-color: var(--card-background);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 30px;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.section-title {
  font-size: 1.8rem;
  color: var(--primary-color);
  margin-bottom: 20px;
}

input, select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  color: var(--text-color);
  background-color: var(--card-background);
}

button {
  background-color: var(--primary-color);
  color: var(--button-text-color);
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

button:hover {
  background-color: var(--primary-hover);
}

.table-header {
  background-color: var(--table-header-background);
  color: var(--table-header-text);
}

.table-header th {
  padding: 10px;
  font-weight: bold;
}

.table-row {
  background-color: var(--card-background);
}

.table-row:nth-child(even) {
  background-color: #f0f8ff; /* Light blue for even rows */
}

.table-row td {
  padding: 4px 8px;
  vertical-align: top;
}

.roadmap-card {
  background-color: var(--card-background);
  border-left: 4px solid var(--secondary-color);
  padding: 15px;
  margin-bottom: 15px;
  position: relative;
}

.symbol {
  color: var(--secondary-color);
  margin-right: 10px;
}

.request-link {
  display: inline-block;
  background-color: var(--primary-color);
  color: var(--button-text-color);
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.request-link:hover {
  background-color: var(--primary-hover);
}

.delete-btn {
  background-color: var(--primary-color);
  color: var(--button-text-color);
}

.delete-btn:hover {
  background-color: var(--primary-hover);
}

.add-btn {
  background-color: var(--primary-color);
  color: var(--button-text-color);
  margin-top: 10px;
}

.add-btn:hover {
  background-color: var(--primary-hover);
}

.generate-btn {
  background-color: var(--primary-color);
  color: var(--button-text-color);
  margin-top: 10px;
}

.generate-btn:hover {
  background-color: var(--primary-hover);
}

.btn {
  background-color: #4a90e2; /* Direct color application */
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
  display: inline-block;
  text-decoration: none;
  margin: 5px;
}

.btn:hover {
  background-color: #3a7bc8;
}

.delete-btn {
  background-color: #e41414;
}

.add-btn {
  background-color: #4a90e2;
}

.generate-btn {
  background-color: #4a90e2;
}

.request-link {
  background-color: #4a90e2;
}

@media (max-width: 768px) {
  .hello {
    padding: 10px;
  }

  .card {
    padding: 20px;
  }

  .main-title {
    font-size: 2.5rem;
  }
}

.notice {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 15px;
  color: #856404;
}

.notice p {
  margin: 0;
  font-size: 0.9rem;
}

.styled-textarea {
  width: 100%;
  min-height: 38px;
  resize: vertical;
  margin: 0;
  padding: 8px 12px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  font-family: inherit;
  font-size: 1rem;
}

.styled-textarea:focus {
  outline: none;
  border-color: #409eff;
}

.styled-textarea::placeholder {
  color: #c0c4cc;
  
}

.styled-textarea:disabled {
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  color: #c0c4cc;
  cursor: not-allowed;
}

.styled-select {
  width: 100%;
  min-width: 120px; /* Ensures minimum width to show full text */
  padding: 8px 12px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  font-family: inherit;
  font-size: 1rem;
  background-color: #fff;
  color: #606266;
  cursor: pointer;
  transition: border-color 0.2s;
  appearance: none; /* Removes default browser styling */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23606266' d='M6 8.825L1.175 4 2.238 2.938 6 6.7l3.763-3.762L10.825 4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  padding-right: 32px; /* Make room for custom arrow */
  display: block; /* Prevents unwanted spacing */
  margin: 0; /* Removes any default margins */
}

.styled-select:focus {
  outline: none;
  border-color: #409eff;
}

.styled-select:disabled {
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  color: #c0c4cc;
  cursor: not-allowed;
}

table td {
  padding: 4px 8px;
  vertical-align: top;
}

/* Remove any margin/padding from select container */
td select {
  margin: 0;
  width: 100%;
  display: block;
}

/* Ensure consistent input widths */
td input, 
td select, 
td textarea {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
}

/* Add specific width for delete button column */
td:last-child {
  width: 100px; /* Adjust based on your needs */
  padding-left: 16px; /* Add space between textarea and delete button */
}

/* Add proper spacing for category controls */
.category-controls {
  display: flex;
  gap: 10px; /* Adds space between category and delete button */
  align-items: flex-start;
  margin-top: 0; /* Removes any top margin */
}

/* If you're using the textarea version */
.styled-textarea {
  /* ... existing textarea styles ... */
  display: block;
  margin-right: 10px; /* Adds space between textarea and delete button */
}

/* If you're using the modal version */
.category-item {
  /* ... existing category-item styles ... */
  margin-right: 10px; /* Adds space between category inputs and delete button */
}

.action-buttons {
  display: flex;
  gap: 4px;  /* Space between buttons */
  align-items: center;
  white-space: nowrap;
  min-width: 90px;  /* Ensure consistent width */
}

.btn {
  padding: 6px 8px;
  min-width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.save-btn {
  background-color: #4CAF50;
}

.edit-btn {
  background-color: #FFA500;
}

.delete-btn {
  background-color: #e41414;
}
.scrollable-wrapper {
  max-height: 400px; /* Adjust height as needed */
  overflow: auto;
  margin: 1rem 0;
  border-radius: 8px;
  border: 1px solid #333;
}

.result-container {
  background: #cec9c4;
  overflow: hidden;
  max-width: 900px;
  margin: 0 auto;
}

.result-container h3 {
  margin: 0;
  padding: 1rem;
  color: white;
  text-align: center;
  position: sticky;
  top: 0;
  background: #1E1E1E;
  z-index: 1;
}

pre {
  margin: 0;
  padding: 1.5rem;
  overflow-x: auto;
  text-align: left;
}

code {
  font-family: 'Consolas', 'Monaco', 'Courier New', monospace;
  font-size: 14px;
  line-height: 1.6;
  display: block;
  min-width: fit-content;
}

/* Scrollbar styling */
.scrollable-wrapper::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scrollable-wrapper::-webkit-scrollbar-track {
  background: #1E1E1E;
}

.scrollable-wrapper::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 4px;
}

.scrollable-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scrollable-wrapper::-webkit-scrollbar-corner {
  background: #1E1E1E;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.info-icon {
  cursor: help;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.info-icon:hover {
  opacity: 1;
}

.tooltip-content {
  max-width: 250px;
  padding: 8px;
}

.tooltip-content strong {
  display: block;
  margin-bottom: 8px;
}

.tooltip-content p {
  margin: 8px 0;
  white-space: pre-line;
}

.tooltip-content em {
  display: block;
  margin-top: 8px;
  font-size: 0.9em;
  color: #e0e0e0;
}

/* Ensure header alignment */
th {
  padding: 12px 8px;
  text-align: center;
  white-space: nowrap;
}
</style>

