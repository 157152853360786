<template>
  <svg class="database-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="url(#gradient)" stroke="url(#gradient)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
    <defs>
      <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" stop-color="#4CAF50" />
        <stop offset="50%" stop-color="#2196F3" />
        <stop offset="100%" stop-color="#9C27B0" />
      </linearGradient>
    </defs>
    <ellipse cx="12" cy="5" rx="9" ry="3"/>
    <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"/>
    <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"/>
  </svg>
</template>

<script>
export default {
  name: 'DatabaseIcon'
}
</script>

<style scoped>
.database-icon {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
}
</style>
